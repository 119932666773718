$(document).ready(function () {
  // find all loading-spinners and change gradient colors
  const loadingSpinners = document.querySelectorAll('.loading-container .loading-spinner');
  for (const loadingSpinner of loadingSpinners) {
    const colorStops = loadingSpinner.querySelectorAll('svg stop');
    for (const colorStop of colorStops) {
      const offset = colorStop.getAttribute('offset');
      if (offset === '0%') {
        colorStop.setAttribute('stop-color', 'rgb(15,148,122)');
      }
      if (offset === '100%') {
        colorStop.setAttribute('stop-color', 'rgb(0,31,71)');
      }
    }
  }
});
